import Vue from 'vue';
import axios from 'axios'
import { validate } from 'uuid';

const state = {
    allTagQueryList: {},
    singleTagQueryList: {},
    multipleTagQueryList: {},
}

const mutations = {
    SET_ALL_TAG_QUERY_LIST(state, tqList) {
        Vue.set(state, 'allTagQueryList', tqList);
    },
    SET_ONE_TAG_QUERY_LIST(state, tqList) {
        Vue.set(state, 'singleTagQueryList', tqList);
    },
    SET_MULTIPLE_TAG_QUERY_LIST(state, tqList) {
        Vue.set(state, 'multipleTagQueryList', tqList);
    },
}

const actions = {
    async getTQList({ commit }, parms) {
        let tqList = await axios.get(`${process.env.VUE_APP_API_MSD}/tqthread?offer_id=&query_type=${parms.tagType}&correspondence_status=${parms.st.cs}
        &cost_impact=${parms.st.ci}&token=${parms.token}&access_id=${parms.access_id}&vendor_id=${parms.vendor_id}&discipline=${parms.st.dc}&category=${parms.st.ct}`);
        // let tqList = await axios.get(`https://827002d9-b84a-4a52-a957-de2c8c180304.mock.pstmn.io/tq`);
        tqList.data.data.forEach(item => item['checked'] = false);
        if (parms.tagType === 'one_tag' || parms.tagType === 'one_sub_package') {
            commit('SET_ONE_TAG_QUERY_LIST', tqList.data);
        } else if (parms.tagType === 'more_than_one_tag' || parms.tagType === 'more_than_one_sub_package') {
            commit('SET_MULTIPLE_TAG_QUERY_LIST', tqList.data);
        } else {
            commit('SET_ALL_TAG_QUERY_LIST', tqList.data);
        }
    },
    updateQueryStatus({ state, commit }, obj) {
        if (obj.tagType === 'one_tag' || obj.tagType === 'one_sub_package') {
            const singleTagQueryList = state.singleTagQueryList.data;
            if (obj.selectionType == 'single') {
                singleTagQueryList.find(item => item._id === obj.queryId).checked = obj.checked;
            } else {
                singleTagQueryList?.forEach((item) => {
                    if (!item.tag_ids[0].tag_disabled && item.correspondence_status === 'Not Published' && item.tq_status === 'Open' && !obj.user && item.category !== 'SubVendor-Vendor Query' || !item.tag_ids[0].tag_disabled && item.correspondence_status === 'Not Published' && item.tq_status === 'Open' && obj.user) {
                        item.queries.forEach((it) => {
                            item.checked = obj.checked
                        });
                    }
                })
            }
            commit('SET_ONE_TAG_QUERY_LIST', { ...state.singleTagQueryList, ...{ data: singleTagQueryList } });
        } else if (obj.tagType === 'more_than_one_tag' || obj.tagType === 'more_than_one_sub_package') {
            const multipleTagQueryList = state.multipleTagQueryList.data;
            console.log('updateQueryStatusmultipleTagQueryList', multipleTagQueryList);
            if (obj.selectionType == 'single') {
                multipleTagQueryList.find(item => item._id === obj.queryId).checked = obj.checked;
            } else {
                multipleTagQueryList?.forEach((item) => {
                    if (!item.tag_ids[0].tag_disabled && item.correspondence_status === 'Not Published' && item.tq_status === 'Open' && !obj.user && item.category !== 'SubVendor-Vendor Query' || !item.tag_ids[0].tag_disabled && item.correspondence_status === 'Not Published' && item.tq_status === 'Open' && obj.user) {
                        item.queries.forEach((it) => {
                            item.checked = obj.checked
                            // if (it.to_response.length > 0 || item.category == 'Vendor Query' && it.from_query.length > 0 || it.sub_vendor_queries_list[0].to_response.length > 0) {
                            //     item.checked = obj.checked
                            // }
                        });
                    }
                })
            }
            commit('SET_MULTIPLE_TAG_QUERY_LIST', { ...state.multipleTagQueryList, ...{ data: multipleTagQueryList } });
        } else {
            const allTagQueryList = state.allTagQueryList.data;
            if (obj.selectionType == 'single') {
                console.log('allTagQueryListssss', allTagQueryList);
                allTagQueryList.find(item => item._id === obj.queryId).checked = obj.checked;
            } else {
                allTagQueryList?.forEach((item) => {
                    if (!item.tag_ids[0].tag_disabled && item.correspondence_status === 'Not Published' && item.tq_status === 'Open' && !obj.user && item.category !== 'SubVendor-Vendor Query' || !item.tag_ids[0].tag_disabled && item.correspondence_status === 'Not Published' && item.tq_status === 'Open' && obj.user) {
                        item.queries.forEach((it) => {
                            item.checked = obj.checked
                        });
                    }
                })
            }
            commit('SET_ALL_TAG_QUERY_LIST', { ...state.allTagQueryList, ...{ data: allTagQueryList } });
        }
    },
    publishQueries({ state }, tqData) {
        const queryList = [...state.allTagQueryList.data || [], ...state.multipleTagQueryList.data || [], ...state.singleTagQueryList.data || []];
        const selectedTQId = queryList.filter((item) => item.checked && !item.tag_ids[0].tag_disabled && (item.correspondence_status === 'Not Published' || item.correspondence_status === 'Query With Procurement')).map(item => item._id);
        const publishData = { ...tqData, thread_ids: selectedTQId, vendor_id: JSON.parse(localStorage.getItem('_loc')).vendor_id }
        console.log('publishData', publishData);
        return axios.post(`${process.env.VUE_APP_API_MSD}/tqthread/publish`, publishData);
    },
    bulkDelete({ state }) {
        const queryList = [...state.allTagQueryList.data || [], ...state.multipleTagQueryList.data || [], ...state.singleTagQueryList.data || []];
        const selectedTQId = queryList.filter((item) => item.checked).map(item => item._id);
        console.log('delete queries', selectedTQId);
    },
    async getDiscipline({ dispatch }, params) {
        // if (this.$router.query?.package_id) {
        //     params.type = 'packages'
        // }
        console.log(this.$router);
        const data = await axios.post(`${process.env.VUE_APP_API}/assignedlisting`, params);
        dispatch('shared/setDisciplines', data.data.disciplines, { root: true });
    },
    async getTQFilters({ commit }, apiParam) {
        return await axios.get(`${process.env.VUE_APP_API_MSD}/tqthread/filters?token=${apiParam.token}&access_id=${apiParam.access_id}&vendor_id=${apiParam.vendor_id}`);
    },
    updateTQ({ commit }, payload) {
        return axios.put(`${process.env.VUE_APP_API_MSD}/tqthread`, payload);
    },
    pushSubvendorRes({ commit }, payload) {
        return axios.post(`${process.env.VUE_APP_API_MSD}/subvendor/push`, payload);
    },
    publishSubQueryRes({ commit }, payload) {
        return axios.put(`${process.env.VUE_APP_API_MSD}/tqthread/response/publish`, payload);
    },
    publishTimeline({ commit }, payload) {
        return axios.post(`${process.env.VUE_APP_API_MSD}/internal/publish`, payload);
    },
    subVendorMails({ commit }, payload) {
        return axios.post(`${process.env.VUE_APP_API_MSD}/vendor/suggestion`, payload);
    },
    publishQueriesTO({ commit }, payload) {
        return axios.post(`${process.env.VUE_APP_API_MSD}/tqthread/publish`, payload);
    },
    async getTQCount({ commit }, payload) {
        return await axios.get(`${process.env.VUE_APP_API_MSD}/tqthread/count?offer_id=${payload.offerId}&token=${payload.token}&vendor_id=${payload.vendorId}`);
    }

}

const getters = {
    getAllTagQueryList() {
        return state.allTagQueryList;
    },
    getSingleTagQueryList() {
        return state.singleTagQueryList;
    },
    getMultipleTagQueryList() {
        return state.multipleTagQueryList;
    },
    getSelectedQueriesOfType: (state) => (type) => {
        console.log('getSelectedQueriesOfType', state, type)
        if (type === 'one_tag' || type === 'one_sub_package') {
            return state.singleTagQueryList?.data?.filter((item) => item.checked).map(item => item) || [];
        } else if (type === 'more_than_one_tag' || type === 'more_than_one_sub_package') {
            return state.multipleTagQueryList?.data?.filter((item) => item.checked).map(item => item) || [];
        } else {
            return state.allTagQueryList?.data?.filter((item) => item.checked).map(item => item) || [];
        }
    },
    getSelectedQuires() {
        const queryList = [
            ...(state.allTagQueryList?.data || []), 
            ...(state.multipleTagQueryList?.data || []), 
            ...(state.singleTagQueryList.data || [])
        ];        
        return queryList.filter((item) => item.checked);
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
