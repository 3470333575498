import Vue from 'vue';
import axios from 'axios'

const state = {
    allTagQueryList: {},
    singleTagQueryList: {},
    multipleTagQueryList: {},
    projectDetails: {},
    selectedTagQueryList: [],
    selectedQueries: []
    // filters: []
}

const mutations = {
    SET_ALL_TAG_QUERY_LIST(state, tqList) {
        Vue.set(state, 'allTagQueryList', tqList);
    },
    SET_ONE_TAG_QUERY_LIST(state, tqList) {
        Vue.set(state, 'singleTagQueryList', tqList);
        console.log('singleTagQueryList', tqList);
    },
    SET_MULTIPLE_TAG_QUERY_LIST(state, tqList) {
        Vue.set(state, 'multipleTagQueryList', tqList);
    },
    SET_PROJECT_DETAILS(state, projectDetails) {
        Vue.set(state, 'projectDetails', projectDetails);
    },
    // SET_FILTERS (state, filters) {
    //     Vue.set(state, 'filters', filters);
    // }
    UPDATE_SELECTED_QUIRES(state, selectedQueries) {
        state.selectedQueries = selectedQueries;
    }
}

const actions = {
    async getProjectDetails({ commit }, offer) {
        const projectDetails = await axios.get(`${process.env.VUE_APP_API}/header?page=tqlisting&offer=${offer}`);
        console.log('action', projectDetails, '123', offer);
        commit('SET_PROJECT_DETAILS', projectDetails.data);
    },
    async getTQList({ commit }, parms) {
        let tqList = await axios.get(`${process.env.VUE_APP_API_MSD}/tqthread?offer_id=${parms.offerId}&query_type=${parms.tagType}&correspondence_status=${parms.st.cs}
      &cost_impact=${parms.st.ci}&tq_status=${parms.st.tq}&discipline=${parms.st.dc}&category=${parms.st.ct}`);
        // let tqList = await axios.get(`https://827002d9-b84a-4a52-a957-de2c8c180304.mock.pstmn.io/tq`);
        tqList.data.data.forEach(item => item['checked'] = false);
        if (parms.tagType === 'one_tag' || parms.tagType === 'one_sub_package') {
            commit('SET_ONE_TAG_QUERY_LIST', tqList.data);
        } else if (parms.tagType === 'more_than_one_tag' || parms.tagType === 'more_than_one_sub_package') {
            commit('SET_MULTIPLE_TAG_QUERY_LIST', tqList.data);
        } else {
            commit('SET_ALL_TAG_QUERY_LIST', tqList.data);
        }
    },
    updateQueryStatus({ state, commit }, obj) {
        if (obj.tagType === 'one_tag' || obj.tagType === 'one_sub_package') {
            const singleTagQueryList = state.singleTagQueryList.data;
            if (obj.selectionType == 'single') {
                singleTagQueryList.find(item => item._id === obj.queryId).checked = obj.checked;
            } else {
                singleTagQueryList?.forEach((item) => {
                    if(!item.tag_ids[0].tag_disabled && (item.category == 'Vendor Query' && item.correspondence_status === 'Not Published' && item.queries[0].to_response.length > 0 || item.category !== 'Vendor Query' && item.correspondence_status === 'Not Published' || !state.singleTagQueryList.owner && item.correspondence_status === 'Query With Procurement' || !state.singleTagQueryList.owner && (item.correspondence_status === 'Response Received' || item.correspondence_status === 'Not Published')) && item.tq_status === 'Open'){
                        item.checked = obj.checked
                    }
                })
            }
            commit('SET_ONE_TAG_QUERY_LIST', {...state.singleTagQueryList,...{data:singleTagQueryList}});
            console.log('state',state);
        } else if (obj.tagType === 'more_than_one_tag' || obj.tagType === 'more_than_one_sub_package') {
            const multipleTagQueryList = state.multipleTagQueryList.data;
            if (obj.selectionType == 'single') {
                multipleTagQueryList.find(item => item._id === obj.queryId).checked = obj.checked;
            } else {
                multipleTagQueryList?.forEach((item) => {
                    if(!item.tag_ids[0].tag_disabled && (item.category === 'Vendor Query' && item.correspondence_status === 'Not Published' && item.queries[0].to_response.length > 0 || item.category !== 'Vendor Query' && item.correspondence_status === 'Not Published' || !state.multipleTagQueryList.owner && item.correspondence_status === 'Query With Procurement' || !state.multipleTagQueryList.owner && (item.correspondence_status === 'Response Received' || item.correspondence_status === 'Not Published')) && item.tq_status === 'Open'){
                        item.checked = obj.checked
                    }
                })
            }
            commit('SET_MULTIPLE_TAG_QUERY_LIST', {...state.multipleTagQueryList,...{data:multipleTagQueryList}});
        } else {
            const allTagQueryList = state.allTagQueryList.data;
            if (obj.selectionType == 'single') {
                allTagQueryList.find(item => item._id === obj.queryId).checked = obj.checked;
            } else {
                allTagQueryList?.forEach((item) => {
                    if(!item.tag_ids[0].tag_disabled && (item.category == 'Vendor Query' && item.correspondence_status === 'Not Published' && item.queries[0].to_response.length > 0 || item.category !== 'Vendor Query' && item.correspondence_status === 'Not Published' || !state.allTagQueryList.owner && item.correspondence_status === 'Query With Procurement' || !state.allTagQueryList.owner && (item.correspondence_status === 'Response Received' || item.correspondence_status === 'Not Published')) && item.tq_status === 'Open'){
                        item.checked = obj.checked
                    }
                })
            }
            commit('SET_ALL_TAG_QUERY_LIST', {...state.allTagQueryList,...{data:allTagQueryList}});
        }
    },
    publishQueries({ state }, tqData) {
        const queryList = [...state.allTagQueryList.data || [], ...state.multipleTagQueryList.data || [], ...state.singleTagQueryList.data || []];
        const selectedTQId = queryList.filter((item) => item.checked && !item.tag_ids[0].tag_disabled && (item.correspondence_status === 'Not Published' || item.correspondence_status === 'Query With Procurement')).map(item => item._id);
        const publishData = { ...tqData, thread_ids: selectedTQId }
        console.log('publishData', publishData);
        return axios.post(`${process.env.VUE_APP_API_MSD}/tqthread/publish`, publishData);
    },
    bulkDelete({ state }) {
        const queryList = [...state.allTagQueryList.data || [], ...state.multipleTagQueryList.data || [], ...state.singleTagQueryList.data || []];
        const selectedTQId = queryList.filter((item) => item.checked).map(item => item._id);
        console.log('delete queries', selectedTQId);
    },
    async getTQFilters({ commit }, apiParam) {
        return await axios.get(`${process.env.VUE_APP_API_MSD}/tqthread/filters?offer_id=${apiParam}`);
    },
    updateTQTechSpec({ commit }, payload) {
        return axios.put(`${process.env.VUE_APP_API_MSD}/tqthread`, payload);
    },
    async getTQCount({ commit }, offerId) {
        return await axios.get(`${process.env.VUE_APP_API_MSD}/tqthread/count?offer_id=${offerId}`);
    },
    setSelectedQuires({ commit}, quires){
        commit('SET_ALL_TAG_QUERY_LIST', quires);
        commit('SET_ONE_TAG_QUERY_LIST', quires);
        commit('SET_MULTIPLE_TAG_QUERY_LIST', quires);
    },

}

const getters = {
    getAllTagQueryList() {
        return state.allTagQueryList;
    },
    getSingleTagQueryList() {
        return state.singleTagQueryList;
    },
    getMultipleTagQueryList() {
        return state.multipleTagQueryList;
    },
    getProjectDetails() {
        return state.projectDetails;
    },
    // getTQFilters () {
    //     return state.filters;
    // },
    getSelectedQueriesOfType: (state) => (type) => {
        if (type === 'one_tag' || type === 'one_sub_package') {
            return state.singleTagQueryList?.data?.filter((item) => item.checked).map(item => item) || [];
        } else if (type === 'more_than_one_tag' || type === 'more_than_one_sub_package') {
            return state.multipleTagQueryList?.data?.filter((item) => item.checked).map(item => item) || [];
        } else {
            return state.allTagQueryList?.data?.filter((item) => item.checked).map(item => item) || [];
        }
    },
    getSelectedQuires() {
        const queryList = [
            ...(state.allTagQueryList?.data || []), 
            ...(state.multipleTagQueryList?.data || []), 
            ...(state.singleTagQueryList.data || [])
        ];        
        return queryList.filter((item) => item.checked);
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
