<template>
  <div>
    <v-autocomplete :readonly="isLocked" :disabled="!disciplines.length" :search-input.sync="search" @change="updateDisciplines()"
      v-model="selectedDisciplines" :multiple="selectionType" elevation="0" item-text="name" item-value="_id"
      :items="disciplines" label="Select Discipline(s)" small-chips solo class="cus-autocomplete">
      <template v-slot:prepend-item v-if="selectionType">
        <v-list-item ripple @mousedown.prevent @click="toggle" v-if="selectionType">
          <v-list-item-action>
            <v-icon :color="selectedDisciplines.length > 0 ? 'indigo darken-4' : ''">
              {{ icon }}
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Select All
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mt-2"></v-divider>
      </template>
      <template v-slot:selection="{ index }" v-if="selectionType">
        <v-chip small v-if="selectedDisciplines.length == 1">{{ index + 1 }} discipline selected</v-chip>
        <v-chip small v-if="index == (selectedDisciplines.length - 1) && selectedDisciplines.length > 1">{{ index + 1 }}
          disciplines selected</v-chip>
      </template>
    </v-autocomplete>
    <v-dialog v-model="disciplineWarning" max-width="500px" content-class="vdialognew">
      <v-card class="py-4">
        <v-card-title class="warning--text">
          Warning
        </v-card-title>
        <v-card-text class="py-5">
          <v-alert dense outlined type="warning" class="">
            User is not a part of the project task force.<br>
            Please <b>add user & discipline</b> in the task force via manage projects
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed rounded color="#ced2d8" @click="disciplineWarning = false">
            Close
          </v-btn>
          <v-btn depressed rounded color="#53c17a" @click="manageProject()" class="white--text px-3">
            Add user & discipline
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    defaultDiscipline: {
      type: Array,
      default: () => []
    },
    selectionType:{
      type:Boolean,
      default:true
    },
    currentTqThread: {
      type: Object,
      default: () => {}
    },
    isIDC: {
      type:Boolean,
      default:false
    },
    isLocked:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      selectedDisciplines: [],
      search:'',
      disciplineWarning:false
    }
  },
  computed: {
    projectDetails () {
        return this.$store.getters['IDC/getProjectDetails'];
    },
    isAssignee() {
        return this.projectDetails?.project?.is_assignee;
    },
    userDiscipline() {
        return this.projectDetails?.project?.user_discipline || '';
    },
    disciplines() {
        if(this.isAssignee && this.userDiscipline) {
            const disciplines = JSON.parse(JSON.stringify(this.$store.getters['shared/getDisciplines'])) || [];
            return disciplines.filter(item => item.name === this.userDiscipline);
        } else {
            const disciplines = JSON.parse(JSON.stringify(this.$store.getters['shared/getDisciplines'])) || [];
            return disciplines.sort();
        }
    },
    selectedAllDisciplines() {
      return this.selectedDisciplines.length === this.disciplines.length
    },
    selectedSomeDisciplines() {
      return this.selectedDisciplines.length > 0 && !this.selectedAllDisciplines
    },
    icon() {
      if (this.selectedAllDisciplines) return 'mdi-close-box'
      if (this.selectedSomeDisciplines) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },
  watch: {
    defaultDiscipline (newVal) {
      if(this.isIDC){
        this.selectedDisciplines = this.disciplines.filter(item => newVal.find(x => x === item._id));
        if(this.defaultDiscipline?.length && !this.selectionType){
          this.selectedDisciplines = this.selectedDisciplines[0]._id
        }
      }
    }
  },
  mounted() {
    if(this.defaultDiscipline[0]?.length){
      this.selectedDisciplines = this.disciplines.filter(item => this.defaultDiscipline.find(x => x === item._id));      
    }else if(this.$route.name !== 'TQ Listing' && this.$route.name !== 'VendorEnqDetails' && this.$route.name != 'VendorDetails' && this.$route.name !== 'Add member' && !this.isIDC && this.$route.name !== 'View TBE (RHS) – Default'){
      this.disciplineWarning = false
    }
    if(this.defaultDiscipline[0]?.length && !this.selectionType){
      this.selectedDisciplines = this.selectedDisciplines[0]._id
    }
    this.$emit('updateDisciplines', this.selectedDisciplines);
  },
  methods: {
    isSelected(item) {
      return this.selectedDisciplines.includes(item);
    },
    toggleItem(item) {
      if (this.selectedDisciplines.includes(item)) {
        this.selectedDisciplines = this.selectedDisciplines.filter(v => v !== item);
      } else {
        this.selectedDisciplines.push(item);
      }
      this.updateDisciplines();
    },
    toggle() {
      if (this.selectedAllDisciplines) {
        this.selectedDisciplines = [];
      } else {
        this.selectedDisciplines = this.disciplines.map(item => item._id);
      }
      this.updateDisciplines();
    },
    updateDisciplines() {
      if(this.$route.name == 'IDC Listing' && !this.selectionType) {
        this.$emit('updateDisciplines', [this.selectedDisciplines]);
      } else {
        this.$emit('updateDisciplines', this.selectedDisciplines);
      }
      if(this.$route.name == 'TQ Listing' || this.$route.name == 'VendorEnqDetails' || this.$route.name == 'VendorDetails' || this.$route.name == 'View TBE (RHS) – Default'){
        this.$emit('updateTQStatus',this.currentTqThread,'disciplineChange')
      }
    },
    manageProject() {
      this.$router.push({ path: `/projects/members?project_id=${this.$route.query.project_id}` })
    },
  }
}
</script>
<style lang="scss">
.v-list-item--highlighted:before {
  display: none;
}
.theme--light.v-list-item--active:before {
  display: none;
}
</style>
